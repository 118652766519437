import type { LevelType, PageCategory } from 'frontend/api/generated';

export const PAGE_LEVEL: LevelType[] = ['ORGANIZATION', 'WORKSPACE'];

export const PAGE_CATEGORIES: PageCategory[] = [
  'ANALYTICS',
  'BUILD',
  'CONNECT',
  'DASHBOARD',
  'INSIGHTS',
  'MEMBERS',
  'SETTINGS',
  'WORKSPACES',
];

export const FILTER_OPTIONS = [
  { value: 'HERE', label: 'Here' },
  { value: 'WORKSPACE', label: 'In workspace' },
  { value: 'ALL', label: 'In organization' },
];

export const COMMENT_FILTER_OPTIONS = [
  { value: 'all', label: 'All comments' },
  { value: 'show-only-pinned', label: 'Pinned' },
  { value: 'show-only-my-comments', label: 'My comments' },
  { value: 'show-archived', label: 'Archived' },
];
