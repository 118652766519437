import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { FiltersDocument, type FiltersType } from 'frontend/api/generated';
import { ArrowLeft, DotsVertical, Filter } from 'frontend/assets/icons';
import { Button, Dropdown, Icon } from 'frontend/components';
import type { MenuOverlayOptions, Option } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { useModal } from 'frontend/features/Modals';
import useMe from 'frontend/hooks/useMe';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './FiltersWrapper.scss';
import type { FiltersProps } from '../../Filters';
import useHasActiveFilters from '../../hooks/useHasActiveFilters';
import useUpdateSavedFilters from '../../hooks/useUpdateSavedFilters';
import { FILTERS_LIST, type FilterType } from '../../utils/config';
import { getFilterWithoutIndex, sortFiltersList } from '../../utils/helpers';
import ActiveFilters from '../ActiveFilters/ActiveFilters';
import UpdateFiltersModal from '../UpdateFiltersModal/UpdateFiltersModal';

function getFilterComponent(componentKey: string, shouldSubmitOnToggle: boolean) {
  const filter = FILTERS_LIST.find((filterItem) => componentKey.startsWith(filterItem.filterKey));
  if (!filter) {
    return null;
  }
  return (
    <div className={styles.filterOverlayWrapper} role="complementary">
      {filter?.component(componentKey, shouldSubmitOnToggle) || null}
    </div>
  );
}

function FiltersWrapper({
  submitOn,
  canIncludeExclude,
  canAddMoreThanOne,
  filters,
  onClear,
  pinnedFilters,
  clearButtonTitle,
  className,
  path,
}: FiltersProps) {
  const { submit, getState, reset, change } = useForm();
  const { values } = getState();
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<Partial<FiltersType> | null>(null);
  const [currentActiveDropdown, setCurrentActiveDropdown] = useState<{ title: string; value: string } | null>(null);
  const [isAddFiltersOpen, setIsAddFiltersOpen] = useState(false);

  const { botId } = useParams();

  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewDialogues = hasBotPerms('view_templates');

  const me = useMe();

  const { data } = useQuery(FiltersDocument, {
    variables: {
      path,
      botId: botId!,
      userId: me.data?.me.id ?? '',
    },
    skip: !isAddFiltersOpen && !me.data?.me.id,
    onCompleted: (response) => {
      if (response.filtersList) {
        const urlParams = new URLSearchParams(window.location.search);
        const savedFilterParamId = urlParams.get('savedFilter');
        if (savedFilterParamId) {
          const selectedFilter = response?.filtersList?.find((filter) => filter?.id === savedFilterParamId);
          setSelectedSavedFilter(selectedFilter ? { ...selectedFilter, user: undefined } : null);
        }
      }
    },
  });

  const updateFilters = useUpdateSavedFilters({
    botId: botId as string,
    path,
    userId: me.data?.me.id ?? '',
    filters: data?.filtersList || [],
    type: 'update',
  });

  const [openSaveFiltersModal] = useModal(UpdateFiltersModal);

  const navigate = useNavigate();
  const location = useLocation();

  const hasActiveFilters = useHasActiveFilters();

  const resetFilters = useCallback(() => {
    let locationPathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const allSearchParams = Array.from(searchParams);
    const remainingSearchParams = allSearchParams.filter(
      ([paramKey]) =>
        ![...FILTERS_LIST.map(({ filterKey }) => filterKey), 'startDate', 'endDate', 'savedFilter'].includes(paramKey),
    );

    if (remainingSearchParams.length > 0) {
      remainingSearchParams.forEach(([key, value], index) => {
        if (index === 0) {
          locationPathname += '?';
        }
        locationPathname += `${key}=${value}`;
      });
    }

    setSelectedSavedFilter(null);
    navigate(locationPathname);
    reset({});
  }, [location.pathname, location.search, navigate, reset]);

  const savedFilterSubmit = useCallback(
    (dataFilters: FiltersType[]) => {
      const deletedFilters = dataFilters.filter(({ deleted }) => deleted);
      if (deletedFilters.some(({ id }) => id === selectedSavedFilter?.id)) {
        resetFilters();
      } else if (dataFilters.length === 1) {
        // creating a new filter or just selecting one
        const [filter] = dataFilters as [FiltersType];
        setSelectedSavedFilter(filter);
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('savedFilter', filter.id);

        navigate(`${location.pathname}?${urlSearchParams.toString()}`);

        reset(filter.filters);
        submit();
      }
    },
    [navigate, location.pathname, resetFilters, selectedSavedFilter, reset, submit],
  );

  const menuOptionsList = useMemo(() => {
    let menu = FILTERS_LIST.filter(({ filterKey }) => {
      if (filters) {
        return filters.includes(filterKey);
      }
      return true;
    })
      .reduce((acc, currValue) => {
        if (!acc.some((val) => val.text === currValue.text)) {
          return [...acc, currValue];
        }
        return acc;
      }, [] as FilterType[])
      .map((option) => ({
        text: option.text,
        key: option.filterKey,
        icon: option.icon,
        closeMenuOnSelection: false,
        disabled:
          !canAddMoreThanOne &&
          Object.keys(values)
            .map((key) => getFilterWithoutIndex(key))
            .includes(option.filterKey),
        onClick: (_, clickOption: Option) => {
          setCurrentActiveDropdown({
            title: clickOption.text,
            value: `${clickOption.key}{${Object.values(values).length}}`,
          });
        },
      })) as MenuOverlayOptions;

    if (!canViewDialogues) {
      menu = menu.filter((item) => (item as Option).key !== 'dialogueFilter');
    }

    if (data?.filtersList?.length) {
      const favorites = data.filtersList.filter((filter) => filter?.isFavorite && filter?.path === path);
      menu.push(
        <div className={styles.separatorTitle}>
          Favorite filters{' '}
          <span
            className={styles.seeAll}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                openSaveFiltersModal({
                  filters: sortFiltersList(data),
                  botId,
                  path,
                  userId: me.data?.me.id ?? '',
                  type: 'update',
                });
              }
            }}
            onClick={() => {
              openSaveFiltersModal({
                filters: sortFiltersList(data),
                botId,
                path,
                userId: me.data?.me.id ?? '',
                type: 'update',
                onClick: (val: FiltersType) => {
                  if (val) {
                    savedFilterSubmit([val]);
                  }
                },
              });
            }}
          >
            see all
          </span>
        </div>,
      );
      if (favorites.length) {
        menu.push('separator');
        favorites
          .sort((a, b) => (a?.updatedAt < b?.updatedAt ? 1 : -1))
          .forEach((filter) => {
            if (filter) {
              menu.push({
                text: filter.name,
                key: filter.id,
                closeMenuOnSelection: true,
                onClick: () => {
                  savedFilterSubmit([filter] as FiltersType[]);
                },
              });
            }
          });
      }
    }

    return menu;
  }, [
    canAddMoreThanOne,
    data,
    botId,
    path,
    filters,
    me.data?.me.id,
    openSaveFiltersModal,
    savedFilterSubmit,
    values,
    canViewDialogues,
  ]);

  const shouldSubmitOnToggle = submitOn === 'toggle';

  return (
    <div className={cx(styles.filtersPanel, className)}>
      {selectedSavedFilter && (
        <div className={styles.selectedSavedFilter}>
          <div>Saved filter</div>
          <div>{selectedSavedFilter.name}</div>
        </div>
      )}
      <Dropdown
        element={Button}
        elementProps={{
          color: 'white',
          size: 'small',
          icon: Filter,
          text: 'Add filter',
        }}
        triggerClassName={styles.trigger}
        overlayClassName={styles.dropdown}
        position="bottom-right"
        title="Add filters"
        outsideClick="pointerdown"
        onToggle={(active) => {
          if (isAddFiltersOpen !== active) {
            setIsAddFiltersOpen(active);
          }
          if (!active) {
            if (currentActiveDropdown && !shouldSubmitOnToggle) {
              submit();
            }
            setCurrentActiveDropdown(null);
          }
        }}
        overlay={
          currentActiveDropdown ? (
            <>
              <div className={styles.backButton}>
                <Icon
                  onClick={() => {
                    setCurrentActiveDropdown((vals) => {
                      if (vals?.value) {
                        change(vals.value, undefined);
                      }
                      return null;
                    });
                  }}
                  component={ArrowLeft}
                />
                {currentActiveDropdown.title}
              </div>
              <div className={styles.activeDropdownWrapper}>
                {getFilterComponent(currentActiveDropdown.value, shouldSubmitOnToggle)}
              </div>
            </>
          ) : (
            <Dropdown.MenuOverlay options={menuOptionsList} />
          )
        }
      />

      {hasActiveFilters && (
        <ActiveFilters
          pinnedFilters={pinnedFilters}
          canIncludeExclude={canIncludeExclude}
          shouldSubmitOnToggle={shouldSubmitOnToggle}
        />
      )}

      {(selectedSavedFilter || hasActiveFilters) && (
        <Dropdown
          data-testid="save-filters-dropdown"
          position="bottom"
          element={Button}
          elementProps={{
            icon: DotsVertical,
            size: 'small',
            flat: true,
          }}
          overlay={
            <Dropdown.MenuOverlay
              options={[
                {
                  text: `${selectedSavedFilter ? 'See all' : 'Save'} filters`,
                  onClick: () => {
                    openSaveFiltersModal({
                      filters: selectedSavedFilter ? sortFiltersList(data) : values,
                      userId: me.data?.me.id ?? '',
                      botId,
                      path,
                      type: selectedSavedFilter ? 'update' : 'create',
                      onClick: (val: FiltersType) => {
                        if (val) {
                          savedFilterSubmit([val]);
                        }
                      },
                      onSave: (val: FiltersType[]) => {
                        if (val) {
                          savedFilterSubmit(val);
                        }
                      },
                    });
                  },
                },
              ]}
            />
          }
        />
      )}

      {selectedSavedFilter?.filters && hasActiveFilters && !isEqual(selectedSavedFilter.filters, values) && (
        <Button
          text="Save"
          color="white"
          className={styles.saveButton}
          onClick={async () => {
            await updateFilters(
              {
                filters: [
                  {
                    ...selectedSavedFilter,
                    filters: values,
                  },
                ],
              },
              undefined,
              (val) => {
                if (val?.[0]) {
                  setSelectedSavedFilter(val[0]);
                }
              },
            );
          }}
        />
      )}

      {(selectedSavedFilter || hasActiveFilters) && (
        <Button
          className={styles.clearFiltersBtn}
          flat
          text={clearButtonTitle || 'Clear filters'}
          onClick={() => {
            if (onClear) {
              onClear(values, reset);
              setSelectedSavedFilter(null);
              return;
            }
            resetFilters();
          }}
        />
      )}
    </div>
  );
}

export default FiltersWrapper;
