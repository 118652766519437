import { type ReactNode, createContext, useMemo, useState } from 'react';

import type { CommentSortType, CommentsFilterType, PageDetailsType } from 'frontend/api/generated';

import { getCommentPath } from '../utils/helpers';
import type { FilterOptionsType } from '../utils/types';

interface CommentsContextType {
  filterType: CommentsFilterType;
  setFilterType: React.Dispatch<React.SetStateAction<CommentsFilterType>>;
  commentsFilter: FilterOptionsType;
  setCommentsFilter: React.Dispatch<React.SetStateAction<FilterOptionsType>>;
  sortFilter: CommentSortType;
  setSortFilter: React.Dispatch<React.SetStateAction<CommentSortType>>;
  pageDetails: Partial<PageDetailsType>;
  isOrganization: boolean;
}

export const CommentsContext = createContext<CommentsContextType>({
  commentsFilter: null,
  setCommentsFilter: () => {},
  filterType: 'ALL',
  setFilterType: () => {},
  sortFilter: 'DESC',
  setSortFilter: () => {},
  pageDetails: {
    level: undefined,
    levelId: undefined,
    category: undefined,
  },
  isOrganization: false,
});

interface ExampleProviderProps {
  children: ReactNode;
}
const CommentsProvider = ({ children }: ExampleProviderProps) => {
  const [filterType, setFilterType] = useState<CommentsFilterType>('ALL');
  const [commentsFilter, setCommentsFilter] = useState<FilterOptionsType>('all');
  const [sortFilter, setSortFilter] = useState<CommentSortType>('DESC');
  const { pathname } = window.location;

  const { levelId, level, category } = getCommentPath(pathname) || {};
  const contextValue = useMemo(
    () => ({
      commentsFilter,
      setCommentsFilter,
      filterType,
      setFilterType,
      sortFilter,
      setSortFilter,
      isOrganization: pathname.startsWith('/organization'),
      pageDetails: {
        level,
        levelId,
        category,
      },
    }),
    [
      commentsFilter,
      pathname,
      filterType,
      setFilterType,
      setCommentsFilter,
      sortFilter,
      setSortFilter,
      levelId,
      level,
      category,
    ],
  );

  return <CommentsContext.Provider value={contextValue}>{children}</CommentsContext.Provider>;
};

export default CommentsProvider;
