import { useApolloClient, useMutation } from '@apollo/client';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import invalidateCacheDialogues from 'frontend/api/cacheHelpers/invalidateCacheDialogues';
import { LibraryDialoguesDocument } from 'frontend/api/generated';
import {
  Check,
  DoubleChevronLeft,
  Duplicate,
  FollowUp,
  Forbid,
  KindlyBlob,
  Refresh,
  Trash,
} from 'frontend/assets/icons';
import { DIALOGUE_TYPES, dragAndDropTypes, isRegularDialogueType } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useLanguages, useToast } from 'frontend/hooks';

import { dropDialogue, dropSamples } from './utils';
import { CACHE_TYPES } from '../../constants';
import { useDeleteDialogue, useDuplicateDialogue, useEnableDialogue, useEnableDialogueMod } from '../../hooks';
import { ResetSkillDialogue, TransferDialogue } from '../../modals';
import { dropDialogueInDialogue, dropSamplesInDialogue } from '../../mutations';
import { dialogueCanHaveFollowups, getBuildUrl, getDialogueTypeName } from '../../utils';
import UpdateColorLabel from '../UpdateColorLabel';

const hasColorLabel = (item) => [CACHE_TYPES.DIALOGUE, CACHE_TYPES.SUGGESTION_DIALOGUE].includes(item.__typename);

export const useDropInDialogue = ({ id, setShowLoader, skillId, currentTitle, currentLanguage }) => {
  const client = useApolloClient();
  const toast = useToast();
  const { buildIdObject } = useBotOrSkill();

  const [dialogueInDialogue] = useMutation(dropDialogueInDialogue, {
    update(cache, { data }) {
      const dialogueMoved = data.dropDialogueInDialogue;

      /* We invalidate the cache for the queries related to the connected dialogues of the moved dialogue (at starting point). */
      const connectedDialoguesAtStart = cache.readQuery({
        query: LibraryDialoguesDocument,
        variables: { ...buildIdObject, parentId: dialogueMoved.id, regular: true, endToEnd: true },
      });
      const connectedDialoguesIdsAtStart =
        connectedDialoguesAtStart?.dialogues.map(({ id: dialogueId }) => dialogueId) || [];
      invalidateCacheDialogues(connectedDialoguesIdsAtStart, buildIdObject, cache);

      /* We invalidate the cache for all the queries related to the connected dialogues of the moved dialogue (at arriving point). */
      const connectedDialoguesIdsAtArrival = dialogueMoved.breadcrumbs.flatMap(({ type, id: dialogueId }) => {
        if (type === 'dialogue') {
          return dialogueId;
        }
        return [];
      });
      invalidateCacheDialogues(connectedDialoguesIdsAtArrival, buildIdObject, cache);

      cache.gc();
    },
  });
  const [samplesInDialogue] = useMutation(dropSamplesInDialogue);

  const drop = useCallback(
    async (item) => {
      try {
        setShowLoader(true);
        if (item.type === dragAndDropTypes.DIALOGUE) {
          await dropDialogue({
            id,
            client,
            toast,
            buildIdObject,
            skillId,
            dialogueInDialogue,
            item,
            currentTitle,
            currentLanguage,
          });
        } else if (item.type === dragAndDropTypes.SAMPLES) {
          await dropSamples({ id, client, toast, buildIdObject, samplesInDialogue, item });
        }
      } finally {
        setShowLoader(false);
      }
    },
    [
      buildIdObject,
      client,
      currentLanguage,
      currentTitle,
      dialogueInDialogue,
      id,
      samplesInDialogue,
      setShowLoader,
      skillId,
      toast,
    ],
  );

  return drop;
};

export const useDialogueContextMenu = ({
  dialogue,
  selectedLanguage,
  disabled,
  parentDisabled,
  skillId,
  isSubscription,
}) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const toast = useToast();
  const botOrSkillParams = useBotOrSkill();

  const { buildIdObject } = botOrSkillParams;
  const dialogueTypeName = getDialogueTypeName(dialogue.dialogueType);

  const { languageNameMapVariants } = useLanguages(botOrSkillParams);
  const enableMod = useEnableDialogueMod({
    dialogue,
    selectedLanguage,
    skillId,
    disabled,
    client,
    buildIdObject,
  });
  const enable = useEnableDialogue({
    dialogue,
    disabled,
    selectedLanguage,
    buildIdObject,
    languageNameMapVariants,
    client,
    toast,
  });
  const duplicate = useDuplicateDialogue({ dialogue, buildIdObject, toast, client });
  const deleteFunction = useDeleteDialogue({
    dialogue,
    selectedLanguage,
    botOrSkillParams,
    languageNameMapVariants,
    client,
    toast,
  });
  const [showTransferDialogueModal] = useModal(TransferDialogue);
  const [showResetSkillDialogue] = useModal(ResetSkillDialogue);
  const contextMenuId = `library-context-menu`;

  const actions = useMemo(() => {
    const { id, topicId, dialogueType, mod } = dialogue;

    const parentIdSegment = `${isSubscription ? 'dialogueModParentId' : 'parentId'}=${get(mod, 'id', id)}`;
    const regular = isRegularDialogueType(dialogueType);
    const { buildType, buildId } = botOrSkillParams;

    const actionsArray = [
      {
        text: `${disabled ? 'Enable' : 'Disable'} ${dialogueTypeName}`,
        icon: disabled ? Check : Forbid,
        onClick: isSubscription ? enableMod : enable,
        disabled: Boolean(disabled && parentDisabled),
      },
    ];

    if (dialogueCanHaveFollowups(dialogue)) {
      actionsArray.unshift({
        text: 'New follow-up',
        icon: FollowUp,
        onClick: () =>
          navigate(getBuildUrl({ buildType, buildId, dialogueType: 'dialogue', target: `new?${parentIdSegment}` })),
      });
    }

    if (isSubscription) {
      if (mod) {
        actionsArray.splice(1, 0, {
          text: 'Reset skill dialogue',
          icon: DoubleChevronLeft,
          onClick: () => showResetSkillDialogue({ ...buildIdObject, skillId, dialogueId: dialogue.id }),
        });
      }

      return actionsArray;
    }

    if (dialogueType !== DIALOGUE_TYPES.SYSTEM_DIALOGUE) {
      actionsArray.splice(1, 0, {
        text: `New ${dialogueTypeName}`,
        icon: KindlyBlob,
        onClick: () => {
          const topicQuery = topicId ? `?topicId=${topicId}` : '';
          navigate(
            getBuildUrl({
              buildType,
              buildId,
              dialogueType: dialogueTypeName,
              target: `new${topicQuery}`,
            }),
          );
        },
      });
      actionsArray.splice(2, 0, 'separator');
      actionsArray.splice(4, 0, {
        text: `Delete ${dialogueTypeName}`,
        icon: Trash,
        onClick: deleteFunction,
        color: 'red',
      });
    }

    if (regular && !dialogue.parentId) {
      actionsArray.splice(2, 0, { text: 'Duplicate dialogue', icon: Duplicate, onClick: duplicate });
      actionsArray.splice(4, 0, {
        text: 'Transfer dialogue',
        icon: Refresh,
        onClick: () => showTransferDialogueModal({ dialogue, botOrSkillParams, selectedLanguage }),
      });
    }

    if (hasColorLabel(dialogue)) {
      actionsArray.push(
        'separator',
        <UpdateColorLabel dialogue={dialogue} isSubscription={isSubscription} skillId={skillId} />,
      );
    }

    return actionsArray;
  }, [
    dialogue,
    isSubscription,
    botOrSkillParams,
    disabled,
    dialogueTypeName,
    enableMod,
    enable,
    parentDisabled,
    navigate,
    showResetSkillDialogue,
    buildIdObject,
    skillId,
    deleteFunction,
    duplicate,
    showTransferDialogueModal,
    selectedLanguage,
  ]);

  return { actions, contextMenuId };
};
