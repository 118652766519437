import { useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { dragAndDropTypes } from 'frontend/constants';
import { updateLibraryTopics } from 'frontend/features/Library/cacheHelpers';
import { useToast } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import { TRANSFER_TOPIC } from '../../mutations';
import { LibraryTopicType } from '../../propTypes';
import TransferForm, { NONE_SELECTED, removeNoneSelected } from '../TransferForm';

const TransferTopic = ({ hide, args }) => {
  const toast = useToast();
  const {
    topic,
    botOrSkillParams,
    botOrSkillParams: { buildIdObject },
  } = args;
  const [transfer] = useMutation(TRANSFER_TOPIC);
  const client = useApolloClient();

  const onSubmit = useCallback(
    async (values) => {
      const { data } = await transfer({ variables: { topicId: topic.id, ...removeNoneSelected(values) } });

      toast.success('The folder is being transferred. This may take a moment...');

      if (!data.transferTopic.deletedOriginal) return;
      const { parentTopicId } = topic;
      const updateVariables = { ...buildIdObject, ...(parentTopicId ? { parentTopicId } : {}) };
      updateLibraryTopics({ variables: updateVariables, client, removedTopic: topic });
    },
    [buildIdObject, client, toast, topic, transfer],
  );

  const initialValues = useMemo(
    () => ({
      name: topic.name,
      deleteOriginal: false,
      botId: NONE_SELECTED,
      skillId: NONE_SELECTED,
    }),
    [topic.name],
  );

  return (
    <TransferForm
      hide={hide}
      onSubmit={onSubmit}
      initialValues={initialValues}
      type={dragAndDropTypes.FOLDER}
      botOrSkillParams={botOrSkillParams}
    />
  );
};

TransferTopic.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ topic: LibraryTopicType.isRequired, botOrSkillParams: BotOrSkillParamsType.isRequired })
    .isRequired,
};

export default TransferTopic;
