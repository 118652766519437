import { combineReducers } from 'redux';

import analytics from './analytics';
import auth, { SIGN_OUT } from './auth';
import bot from './bot';
import build from './build';
import chat from './chat';
import comments from './comments';
import evaluation from './evaluation';
import inbox from './inbox';
import insights from './insights';
import library from './library';
import messageClustering from './messageClustering';
import navbar from './navbar';
import platform from './platform';
import pusher from './pusher';
import tabNotification from './tabNotification';

export { initialState as authInitialState } from './auth';

const createRootReducer = () => {
  const appReducer = combineReducers({
    analytics,
    auth,
    bot,
    build,
    chat,
    evaluation,
    inbox,
    library,
    messageClustering,
    pusher,
    platform,
    navbar,
    tabNotification,
    insights,
    comments,
  });

  return (state, action) => {
    if (action.type === SIGN_OUT.ACTION) {
      state = undefined;
    }
    return appReducer(state, action);
  };
};

export default createRootReducer;
