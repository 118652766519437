import React, { useContext } from 'react';

import { Close, SortAscending, SortDescending } from 'frontend/assets/icons';
import { Badge, Icon } from 'frontend/components';
import ButtonGroup from 'frontend/components/ButtonGroup/ButtonGroup';
import { toggleComments } from 'frontend/state/dux/comments';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from './CommentsHeader.scss';
import { CommentsContext } from '../../context/commentsContext';
import { COMMENT_FILTER_OPTIONS, FILTER_OPTIONS } from '../../utils/constants';

const CommentsHeader = () => {
  const { commentsFilter, sortFilter, filterType, setFilterType, setCommentsFilter, setSortFilter } =
    useContext(CommentsContext);

  const dispatch = useAppDispatch();
  return (
    <div className={styles.commentsHeader}>
      <div className={styles.commentsTitleRow}>
        <div className={styles.commentsTitle}>Comments</div>
        <div className={styles.commentsClose}>
          <Badge title="&#8984; ctrl C" className={styles.commentsShortcutBadge} tooltip="Close comments" />
          <Icon component={Close} color="darkGray" hoverColor="primary" onClick={() => dispatch(toggleComments())} />
        </div>
      </div>
      <div className={styles.commentsActionRow}>
        <ButtonGroup
          buttons={[
            {
              type: 'select',
              key: 'comments-filter',
              props: {
                input: {
                  name: 'comments-filter',
                  value: COMMENT_FILTER_OPTIONS.find((option) => option.value === commentsFilter)
                    ?.value as unknown as string,
                  onChange: (e) => {
                    setCommentsFilter(e.target.value);
                  },
                  onBlur: () => {},
                  onFocus: () => {},
                },
                options: COMMENT_FILTER_OPTIONS,
              },
            },
            {
              type: 'select',
              key: 'filter-type',
              props: {
                input: {
                  name: 'filter-type',
                  value: filterType,
                  onChange: (e) => {
                    if (e.target) {
                      setFilterType(e.target.value);
                    }
                  },
                  onBlur: () => {},
                  onFocus: () => {},
                },
                options: FILTER_OPTIONS,
              },
            },
          ]}
        />
        <Icon
          width={24}
          height={24}
          hoverColor="darken"
          color="darkGray"
          title={sortFilter === 'ASC' ? 'Sort by newest' : 'Sort by oldest'}
          component={sortFilter === 'ASC' ? SortDescending : SortAscending}
          onClick={() =>
            setSortFilter((current) => {
              if (current === 'ASC') {
                return 'DESC';
              }
              return 'ASC';
            })
          }
        />
      </div>
    </div>
  );
};

export default CommentsHeader;
