import { useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import { useContext } from 'react';

import { CommentPageDocument, type CommentPagePaginatedType, type CommentPageQuery } from 'frontend/api/generated';

import { CommentsContext } from '../context/commentsContext';

type UseCommentsQueryReturnType = {
  loadMore: () => void;
  data: CommentPageQuery | undefined;
  loading: boolean;
  error: Error | undefined;
};

export default (): UseCommentsQueryReturnType => {
  const { pathname } = window.location;

  const {
    sortFilter,
    filterType,
    commentsFilter,
    pageDetails: { level, levelId, category },
  } = useContext(CommentsContext);

  const { data, loading, error, fetchMore } = useQuery(CommentPageDocument, {
    variables: {
      level: level as string,
      levelId: levelId as string,
      category: category as string,
      path: pathname,
      sort: sortFilter,
      paginationSize: 10,
      filterType,
      paginationPage: 1,
      archived: commentsFilter === 'show-archived',
      onlyUsersComments: commentsFilter === 'show-only-my-comments',
      pinned: commentsFilter === 'show-only-pinned',
    },
    fetchPolicy: 'network-only',
    skip: !level || !levelId || !category,
    pollInterval: 20000,
  });

  const loadMore = () => {
    fetchMore({
      variables: { paginationPage: (data?.commentPage?.page || 0) + 1 },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const mergedComments = uniqBy(
          [...(prev.commentPage?.comments || []), ...(fetchMoreResult.commentPage?.comments || [])],
          'id',
        );

        return {
          ...prev,
          commentPage: {
            ...(prev.commentPage as CommentPagePaginatedType),
            comments: mergedComments,
            page: fetchMoreResult.commentPage?.page || prev.commentPage?.page || 1,
            pages: fetchMoreResult.commentPage?.pages ?? prev.commentPage?.pages ?? 1,
            hasNext: fetchMoreResult.commentPage?.hasNext ?? prev.commentPage?.hasNext ?? false,
            hasPrev: fetchMoreResult.commentPage?.hasPrev ?? prev.commentPage?.hasPrev ?? false,
            startIndex: fetchMoreResult.commentPage?.startIndex ?? prev.commentPage?.startIndex ?? 0,
            endIndex: fetchMoreResult.commentPage?.endIndex ?? prev.commentPage?.endIndex ?? 0,
            count: fetchMoreResult.commentPage?.count ?? prev.commentPage?.count ?? 0,
          },
        };
      },
    });
  };

  return { loadMore, data, loading, error };
};
