import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import {
  CommentPageDocument,
  type CommentPagePaginatedType,
  type CommentSortType,
  type CommentsFilterType,
  MutateCommentPageDocument,
  type PageDetailsType,
} from 'frontend/api/generated';

import { CommentsContext } from '../context/commentsContext';

type MutateContextType = {
  level: string;
  levelId: string;
  category: string;
  sortFilter: CommentSortType;
  commentsFilter: string;
  filterType: CommentsFilterType;
  pathname: string;
  archive?: boolean;
  pin?: boolean;
};

export default () => {
  const {
    pageDetails: { level, levelId, category },
    commentsFilter,
    filterType,
    sortFilter,
  } = useContext(CommentsContext);

  const { pathname } = window.location;
  const [mutateComment] = useMutation(MutateCommentPageDocument, {
    update: (cache, { data: comments }, options) => {
      if (!comments?.mutateCommentPage) return;
      const { mutateCommentPage } = comments;

      const { context } = options;
      const {
        level: mutateLevel,
        levelId: mutateLevelId,
        category: mutateCategory,
        sortFilter: mutateSortFilter,
        commentsFilter: mutateCommentsFilter,
        filterType: mutateFilterType,
        pathname: mutatePathname,
        archive: mutateArchive,
      } = context as MutateContextType;

      cache.updateQuery(
        {
          query: CommentPageDocument,
          variables: {
            level: mutateLevel as string,
            levelId: mutateLevelId as string,
            category: mutateCategory as string,
            filterType: mutateFilterType,
            sort: mutateSortFilter,
            paginationSize: 10,
            paginationPage: 1,
            path: mutatePathname,
            archived: mutateCommentsFilter === 'show-archived',
            onlyUsersComments: mutateCommentsFilter === 'show-only-my-comments',
            pinned: mutateCommentsFilter === 'show-only-pinned',
          },
        },
        (data) => {
          const currentComments = [...(data?.commentPage?.comments || [])];
          const existingCommentIndex = currentComments.findIndex(
            (comment) => comment.id === mutateCommentPage.comments[0]?.id,
          );
          const isUpdate = existingCommentIndex !== -1 && currentComments[existingCommentIndex];

          if (isUpdate) {
            // Update existing comment
            if (mutateCommentPage.comments[0]) {
              // We only care about removing the comment from the current list
              // The other lists will be refetched anyway with the fetch-policy set to network-only
              if (typeof mutateArchive === 'boolean') {
                currentComments.splice(existingCommentIndex, 1);
              }
            }
          } else if (mutateCommentPage.comments[0]) {
            // Created new comment, add to the list
            if (sortFilter === 'ASC') {
              currentComments.push(mutateCommentPage.comments[0]);
            } else {
              currentComments.unshift(mutateCommentPage.comments[0]);
            }
          }

          return {
            ...data,
            commentPage: {
              ...(data?.commentPage as CommentPagePaginatedType),
              count: !isUpdate ? (data?.commentPage?.count ?? 0) + 1 : (data?.commentPage?.count ?? 0),
              comments: currentComments,
            },
          };
        },
      );
    },
  });

  const updateComment = async ({
    id,
    text,
    pin,
    archive,
    pageDetails,
  }: {
    id: string;
    pageDetails: PageDetailsType;
    text?: string;
    pin?: boolean;
    archive?: boolean;
  }) =>
    mutateComment({
      variables: {
        level: pageDetails.level as string,
        levelId: pageDetails.levelId as string,
        category: pageDetails.category as string,
        path: pageDetails.path as string,
        update: {
          text,
          id,
          pin,
          archive,
        },
      },
      context: {
        level,
        levelId,
        category,
        pathname,
        sortFilter,
        commentsFilter,
        archive,
        filterType,
        pin,
      },
    });

  const createComment = async (text: string) =>
    mutateComment({
      variables: {
        level: level as string,
        levelId: levelId as string,
        category: category as string,
        path: pathname,
        create: {
          text,
        },
      },
      context: {
        level,
        levelId,
        category,
        sortFilter,
        pathname,
        filterType,
        commentsFilter,
      },
    });

  return { updateComment, createComment };
};
