import { useApolloClient, useMutation } from '@apollo/client';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import invalidateCacheDialogues from 'frontend/api/cacheHelpers/invalidateCacheDialogues';
import {
  type DialogueType,
  DropTopicInTopicDocument,
  LibraryDialoguesDocument,
  type TopicType,
} from 'frontend/api/generated';
import { FolderPlus, KindlyBlob } from 'frontend/assets/icons';
import { DIALOGUE_TYPES, dragAndDropTypes } from 'frontend/constants';
import { CreateTopic } from 'frontend/features/Library/modals';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useCurrentLanguage, useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { updateDialogueParentOnRemove, updateLibraryDialogues, updateLibraryTopics } from '../../cacheHelpers';
import { dropDialogueInTopic } from '../../mutations';
import { getBuildUrl } from '../../utils';

type Props = {
  buildIdObject: BuildIdObject;
  setShowLoader: CallableFunction;
};
export const useDropInFolder = ({ buildIdObject, setShowLoader }: Props) => {
  const client = useApolloClient();
  const [{ currentLanguage }] = useCurrentLanguage();
  const toast = useToast();
  const [dialogueInTopic] = useMutation<{ dropDialogueInTopic: DialogueType }>(dropDialogueInTopic, {
    update(cache, _, { variables }) {
      const dialogueId = variables?.sourceDialogueId;

      /* We invalidate the cache for the queries related to the connected dialogues of the moved dialogue (at starting point). */
      const connectedDialoguesAtStart = cache.readQuery({
        query: LibraryDialoguesDocument,
        variables: { ...buildIdObject, parentId: dialogueId, regular: true, endToEnd: true },
      });
      const connectedDialoguesIdsAtStart = connectedDialoguesAtStart?.dialogues.map(({ id }) => id) || [];
      invalidateCacheDialogues(connectedDialoguesIdsAtStart, buildIdObject, cache);

      cache.gc();
    },
  });
  const [topicInTopic] = useMutation<{ dropTopicInTopic: TopicType }>(DropTopicInTopicDocument);

  const drop = useCallback(
    async (item) => {
      setShowLoader(true);
      try {
        if (item.type === dragAndDropTypes.DIALOGUE) {
          let dialogue: DialogueType | undefined;
          try {
            const result = await dialogueInTopic({
              variables: {
                ...buildIdObject,
                sourceDialogueId: item.id,
              },
            });
            dialogue = get(result, 'data.dropDialogueInTopic');
          } catch (error) {
            toast.error(error.message);
            return;
          }

          if (!dialogue) {
            return;
          }

          // Update parent of dragged dialogue
          updateDialogueParentOnRemove({ dialogue: item, buildIdObject, client });
          // Update dialogues in this folder
          updateLibraryDialogues({
            variables: { regular: true, ...buildIdObject },
            client,
            addedDialogue: dialogue,
            removedDialogue: undefined,
          });
          toast.success(`Dialogue '${item.title[currentLanguage]}' moved to root level`);
        } else if (item.type === dragAndDropTypes.FOLDER) {
          let topic: TopicType | undefined;
          try {
            const result = await topicInTopic({ variables: { ...buildIdObject, sourceTopicId: item.id } });
            topic = get(result, 'data.dropTopicInTopic');
          } catch (error) {
            toast.error(error.message);
            return;
          }

          if (!topic) {
            return;
          }

          // Update subtopics in this folder
          updateLibraryTopics({ variables: buildIdObject, client, addedTopic: topic, removedTopic: undefined });
          // Update subtopics in parent of dragged topic
          const variables = { ...buildIdObject, parentTopicId: item.parentTopicId };
          updateLibraryTopics({ variables, client, removedTopic: topic, addedTopic: undefined });
          toast.success(`Topic '${item.name}' moved to root level`);
        }
      } finally {
        setShowLoader(false);
      }
    },
    [buildIdObject, client, currentLanguage, dialogueInTopic, setShowLoader, toast, topicInTopic],
  );

  return { drop };
};

export const useDialogueFolderContextMenu = () => {
  const [showCreateTopic] = useModal(CreateTopic);
  const navigate = useNavigate();
  const { buildIdObject, buildType, buildId } = useBotOrSkill();

  const contextMenuId = 'library-context-menu';
  const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType: DIALOGUE_TYPES.REGULAR, target: 'new' });

  const actions = useMemo(
    () => [
      { text: 'New dialogue', icon: KindlyBlob, onClick: () => navigate(newDialogueUrl) },
      { text: 'New folder', icon: FolderPlus, onClick: () => showCreateTopic({ buildIdObject }) },
    ],
    [buildIdObject, navigate, showCreateTopic, newDialogueUrl],
  );

  return { actions, contextMenuId };
};
