import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import { GET_AVAILABLE_TIMEZONES } from 'frontend/api/queries';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import {
  Button,
  FileUpload,
  FormErrors,
  Input,
  LoaderSwitch,
  PageBar,
  Panel,
  SelectWithSearch,
} from 'frontend/components';
import { useGeneralForm } from 'frontend/features/BotSettings/hooks';
import { chain, max, required } from 'frontend/form/validators';

import styles from '../../styles.scss';

export default function BotDetails() {
  const { onSubmit, initialValues, loading, setFile } = useGeneralForm();

  const { data, loading: loadingTimezones } = useQuery(GET_AVAILABLE_TIMEZONES);
  const availableTimezones = data?.availableTimezones;

  const groupSelectTimezones = useMemo(() => {
    if (!availableTimezones) {
      return [];
    }
    const groupObject = groupBy(availableTimezones, (tz) => tz.split('/')[0]);
    return Object.entries(groupObject).map(([groupName, timezones]) => ({
      name: groupName,
      type: 'group',
      items: timezones.map((tz) => ({ name: tz, value: tz })),
    }));
  }, [availableTimezones]);

  return (
    <LoaderSwitch loading={loading || loadingTimezones} size="large">
      <div className={styles.generalLayout}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form: { change }, values }) => (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>

              <Panel>
                <FormErrors />
                <h3 className={`${styles.sectionTitle} m-b-3`}>Workspace avatar</h3>
                <div className={styles.avatarContainer}>
                  <div
                    className={styles.portrait}
                    style={{ backgroundImage: `url(${values.bot?.avatarUrl || defaultBotAvatar})` }}
                  />
                  <div className={styles.upload}>
                    <Field component={Input} name="bot.avatarUrl" hidden />
                    <FileUpload
                      containerClassName={styles.uploadButton}
                      text="Upload"
                      accept="image/png,image/jpeg"
                      onUpload={(f) => {
                        change('bot.avatarUrl', f?.[0]?.source);
                        setFile!(f?.[0]?.file);
                      }}
                    />
                    {initialValues.bot?.avatarUrl && (
                      <Button flat onClick={() => change('bot.avatarUrl', null)}>
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
                <Panel.Separator />
                <h3 className={`${styles.sectionTitle} m-b-3`}>Bot details</h3>
                <div className={styles.inputContainer}>
                  <Field
                    component={Input}
                    label="Bot name"
                    name="bot.name"
                    className="m-b-md"
                    placeholder="Give me a name"
                    inputLimit={50}
                    validate={chain([required, max(50)])}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    component={Input}
                    name="bot.projectName"
                    label="Workspace name"
                    placeholder="Give me a workspace name"
                    inputLimit={50}
                    validate={max(50)}
                  />
                </div>
                <p className="m-t-sm text-color-light">Workspace name is only visible within the Kindly platform.</p>

                <div className={styles.inputContainer}>
                  <h3 className={styles.timezoneLabel}>Workspace timezone</h3>
                  <div className={styles.selectContainer}>
                    <Field component={SelectWithSearch} name="bot.timezone" options={groupSelectTimezones} />
                  </div>
                  <p className="m-t-sm text-color-light">
                    This timezone will be applied universally across your workspace for all time-related features,
                    unless otherwise specified. For example, it will be used to set business hours, configure time-based
                    rules, and display data in analytics.
                  </p>
                </div>
              </Panel>
            </form>
          )}
        />
      </div>
    </LoaderSwitch>
  );
}
