import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { BotSettingsDocument } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';

import BusinessHoursTable from './BusinessHoursTable';
import styles from '../../InboxSettings.scss';

const BusinessHours = () => {
  const { botId } = useParams();

  const { data: settingsData, loading } = useQuery(BotSettingsDocument, {
    variables: { botId: botId! },
  });

  return (
    <LoaderSwitch loading={loading}>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Business hours</h3>
      <p className={styles.helpText}>
        If you have enabled handover, you can also choose if you want to set your business hours. These settings define
        what should happen when a user clicks the button for handover request. During business hours, this will trigger
        a notification to all agents. Outside of business hours, the bot will instead answer with the message you define
        below.
      </p>
      <p className={styles.helpText}>
        If you have enabled handover but don&apos;t use business hours, the bot will consider your business hours to be
        always open.
      </p>

      <p className={styles.helpText}>
        The timezone used for business hours is the <b>workspace timezone</b>, which currently is set to{' '}
        <b>{settingsData?.bot?.timezone}</b>.
      </p>

      <div className={styles.scheduleContainer}>
        <div className="m-t-xl">
          <BusinessHoursTable />
        </div>
      </div>
    </LoaderSwitch>
  );
};

export default BusinessHours;
