import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ErrorBoundary } from 'frontend/components';
import { Feedback, NotFound, SubscribeToSkill } from 'frontend/features';
import { ChangelogEntryModal } from 'frontend/features/Changelog';
import { Feature, Support } from 'frontend/features/Feedback/views';
import { useModal } from 'frontend/features/Modals/hooks';
import Profile from 'frontend/features/Profile';
import TrackingConsentModal from 'frontend/features/Profile/modals/TrackingConsentModal';
import PusherProvider, { PusherMultiple, getPusherChannel } from 'frontend/features/Pusher';
import { useAllowTracking } from 'frontend/features/Tracking/hooks';
import { useBotOrSkill } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import AuthenticatedLayout from 'frontend/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { selectAuthenticated } from 'frontend/state/dux/auth';
import { unsetCurrent } from 'frontend/state/dux/navbar';

// import DebugAuth from 'frontend/routes/DebugAuth';
import AdminRoutes from './AdminRoutes';
import BotRoutes from './BotRoutes';
import OrganizationRoutes from './OrganizationRoutes';
import OrganizationsListRoute from './OrganizationsListRoute';
import SkillRoutes from './SkillRoutes';
import { useAuthenticatedEvents } from './hooks';

const AuthenticatedRoutes = () => {
  const { data } = useMe();
  const [showChangelogEntryModal] = useModal(ChangelogEntryModal);
  const [showTrackingConsentModal] = useModal(TrackingConsentModal);
  const location = useLocation();
  const authenticated = useSelector(selectAuthenticated);

  const dispatch = useDispatch();

  const { buildIdObject } = useBotOrSkill({ ignoreNoBotOrSkill: true });
  const [hasSeenChangelog, setHasSeenChangelog] = useState(false);

  // We put the realtime updates this far up in the tree to avoid the situation where a
  // user is on a component, leaves it, misses realtime updates and then goes back to it
  const events = useAuthenticatedEvents();
  useAllowTracking();

  useEffect(() => {
    if (buildIdObject === undefined) {
      dispatch(unsetCurrent());
    }
  }, [buildIdObject, dispatch]);

  useEffect(() => {
    // If Cypress is running, we don't want to show any modal
    if (window.Cypress) {
      return undefined;
    }

    if (!data?.me || data?.me?.profile?.trackingConsentApproved) {
      return undefined;
    }

    const unreadChangelogEntry = Boolean(data?.me?.profile?.unreadChangelogEntry);
    const unreadTrackingConsent = Boolean(data?.me?.profile?.unreadTrackingConsent);

    let clear;

    if (unreadTrackingConsent) {
      clear = setTimeout(showTrackingConsentModal, 1000);
    } else if (unreadChangelogEntry && !hasSeenChangelog) {
      clear = setTimeout(showChangelogEntryModal, 1000);
    }

    if (clear) {
      setHasSeenChangelog(true);
      return () => {
        clearTimeout(clear);
      };
    }

    return undefined;
  }, [data, setHasSeenChangelog, hasSeenChangelog, showChangelogEntryModal, showTrackingConsentModal]);

  if (!authenticated) {
    const next = location.pathname;
    return <Navigate to={`/login${next ? `?next=${next}` : ''}`} />;
  }

  if (!data?.me) {
    return null;
  }
  const workspacePath = `${location.pathname.replace('/bot/', '/workspace/')}${location.search}`;

  return (
    <PusherProvider me={data.me}>
      <AuthenticatedLayout>
        <ErrorBoundary reportError renderErrorView>
          <Routes>
            <Route path="/" element={<Navigate to="organizations" />} />
            <Route path="/bots" element={<Navigate to="organizations" />} />
            <Route path="/skills" element={<Navigate to="organizations" />} />
            <Route path="/bot/:botId/*" element={<Navigate replace to={workspacePath} />} />
            <Route path="/my-organizations" element={<Navigate to="organizations" />} />
            <Route path="/my-organization/:organizationId" element={<Navigate to="organization/:organizationId" />} />
            <Route path="/feature-requests" element={<Navigate to="feedback" />} />
            <Route path="/workspace/:botId/*" element={<BotRoutes />} />
            <Route path="/skill/:skillId/*" element={<SkillRoutes />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
            <Route path="/skills/:skillId/*" element={<SubscribeToSkill />} />
            <Route path="/organizations" element={<OrganizationsListRoute />} />
            <Route path="/organization/:organizationId/*" element={<OrganizationRoutes />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/feedback/feature" element={<Feature />} />
            <Route path="/feedback/support" element={<Support />} />
            {/* Enable the following route to debug authentication */}
            {/* <Route path="/debugauth" element={DebugAuth} /> */}
            <Route element={<NotFound />} />
          </Routes>
          <PusherMultiple channel={getPusherChannel({ userId: data.me.id })} events={events} />
        </ErrorBoundary>
      </AuthenticatedLayout>
    </PusherProvider>
  );
};

export default AuthenticatedRoutes;
